<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="Title"
      color="dark"
      @update:show="resetForm(false)"
      :close-on-backdrop="false"
      :show.sync="ModalActive"
    >
      <CCol>
        <CInput
          v-model.trim="$v.grupo.GpoSupplyName.$model"
          :horizontal="{ label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-8'}"
          :invalid-feedback="errorMessage($v.grupo.GpoSupplyName)"
          :is-valid="hasError($v.grupo.GpoSupplyName)"
          :label="$t('label.name')"
          addLabelClasses="required text-right"
          :placeholder="$t('label.groupName')"
          maxlength="100"
        >
        </CInput>
        <CSelect
          v-if="isEdit"
          :value.sync="grupo.Status"
          :is-valid="statusSelectColor"
          :label="$t('label.status')"
          addLabelClasses="text-right"
          :horizontal="{ label: 'col-sm-12 col-lg-3', input: 'col-sm-12 col-lg-8'}"
          :options="statusOptions"
        />
      </CCol>
      <template #footer>
        <CButton
          color="add"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="isEdit?statusConfirmation(GpoSupplyItem.FgActGpoSupply, grupo.Status, submit):submit()"
        >
          <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
        </CButton>
        <CButton
          color="wipe"
          class="d-flex align-items-center"
          @click.stop="resetForm(false)"
        >
          <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import GrupoFormValidations from '@/_validations/estiba/GrupoFormValidations';
import ModalMixin from '@/_mixins/modal';

function data() {
  return {
    isSubmit: false,
    Loading: false,
		ModalActive: false,
    grupo: {
      GpoSupplyId: '',
      GpoSupplyName: '',
      Status: 1
    },
  }
}

function submit() {
  try {
    this.isSubmit = true;
    this.Loading = true;
    this.$v.grupo.$touch();
    if (this.$v.grupo.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }

    let metodo = this.isEdit ? 'PUT':'POST';
    let ruta = this.isEdit ? 'GpoSupply-update':'GpoSupply-insert';

    let GpoSupplyJson = !this.isEdit
      ? {
          GpoSupplyName: this.grupo.GpoSupplyName
        }
      : {
          GpoSupplyId: this.grupo.GpoSupplyId,
          GpoSupplyName: this.grupo.GpoSupplyName,
          Status: this.grupo.Status
        };

    this.$http.ejecutar(metodo, ruta, GpoSupplyJson, { root: 'GpoSupplyJson' })
    .then(response => {
			this.notifySuccess({text: response.data.data[0].Response});
      this.resetForm(true);
    }).catch(err => {
      this.notifyError({text: err});
    }).then(() => {
      this.isSubmit = false;
      this.Loading = false;
    });
  } catch (e) {
		this.isSubmit = false;
    this.Loading = false;
    this.notifyError({ text: e });
  }
}

function resetForm(Reset) {
  this.grupo.GpoSupplyId = '';
  this.grupo.GpoSupplyName = '';
  this.grupo.Status = 1;
  this.$nextTick(() => { this.$v.$reset() })
	this.$emit('closeModal', Reset);
}

function setGrupo(item) {
  this.grupo.GpoSupplyId = item.GpoSupplyId;
  this.grupo.GpoSupplyName = item.GpoSupplyName;
  this.grupo.Status = item.FgActGpoSupply ? 1 : 0;
  this.$v.$touch();
}

function statusSelectColor(){
  return this.grupo.Status === 1;
}

function Title() {
	if (this.isEdit) {
		return this.$t('label.edit')+' '+this.$t('label.gpoSupply')+': '+this.GpoSupplyItem?.GpoSupplyName
	} else {
		return this.$t('label.nuevo')+' '+this.$t('label.gpoSupply');
	}
}

export default {
  name: 'form-grupo',
  mixins: [
    ModalMixin,
  ],
  props: {
    modal: {
      type: Boolean,
      default: false
    },
		GpoSupplyItem: {
			type: Object,
      default: {}
		},
		isEdit: {
			type: Boolean,
      default: false
		},
  },
  data,
  validations: GrupoFormValidations,
  methods: {
    submit,
    resetForm,
    setGrupo,
  },
  computed: {
    Title,
    statusSelectColor,
  },
  watch: {
    modal: function(newVal) {
			this.ModalActive = newVal;
      if(newVal&&this.isEdit){
				this.setGrupo(this.GpoSupplyItem);
      }
    },
  }
}
</script>