import {
  required,
  maxLength
} from 'vuelidate/lib/validators';

import { onlyText } from '../funciones';

export default () => {
  return {
    grupo: {
      GpoSupplyName: { required, onlyText, maxLength: maxLength(100) }
    }
  }
}